import React, { useEffect, Fragment } from "react";
import { Container, Card, Row, Col } from "reactstrap";
import SEO from "../components/SEO";
import TitleBar from "../components/Layout/TitleBar";
import BreadCrumbs from "../components/Layout/BreadCrumbs";

import Paul from "../assets/images/paul-bio.png";
import Jack from "../assets/images/jack-bio.jpg";
import Daniel from "../assets/images/daniel-bio.jpg";
import Michael from "../assets/images/michael-bio.jpg";
import Jasdeep from "../assets/images/JB-Profile-Photo.png";

import { OutboundLink } from "gatsby-plugin-google-gtag"

const PeoplePage = () => {
  useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  return (
    <Fragment>
      <SEO
        title="People"
        description="Get to know the key contributors at Cannabis and Psychedelics Law Group LLP namely: Paul Lewin, Barrister and Solicitor with focus on cannabis and psychedelics, Jack Lloyd, Barrister and Solicitor with a focus on cannabis, Daniel Perlin, Corporate Commerical Lawyer, Michael Starogiannis, Business Consultant and Jasdeep Bal, Lead Litigation Lawyer." 
      />

      <div className="wrapper mt-5">
        <div className="main bg-grey">
          <Container>
            <TitleBar title="Key People" />
            <BreadCrumbs page="People" />
            <Card className="p-5">
              <Row>
                <Col md="4" className="mb-4">
                  <img
                    className="rounded-circle img-fluid img-raised"
                    src={Paul}
                    alt="Paul Lewin: Cannabis and Psychedelics Lawyer"
                  />
                </Col>
                <Col md="8">
                  <h4 className="font-weight-bold mb-0 mt-0">PAUL LEWIN</h4>
                  <p className="job-title">BARRISTER & SOLICITOR</p>
                  <p>
                    Paul Lewin has been a lawyer in Toronto and across Canada
                    for over 27 years. He practices with Lewin &amp; Sagara LLP
                    in Toronto:{" "}
                    <OutboundLink
                      target="_blank"
                      rel="noreferrer"
                      href="http://www.lewinsagara.ca/"
                    >
                      http://www.lewinsagara.ca/
                    </OutboundLink>{" "}
                    His focus concerns litigation and consultation on matters
                    related to cannabis and psychedelics.
                  </p>
                  <p>
                    Paul has fought cannabis issues at all levels of the Ontario
                    Courts and in other provinces. He litigated the
                    constitutionality of the religious use of cannabis
                    establishing a freedom of religion breach. He challenged the
                    medical cannabis doctor-as- gatekeeper rules under the
                    Medical Marihuana Access Regulations. He challenged the
                    constitutionality of the limited cannabis strain choice
                    under the Medical Marihuana Access Regulations. He
                    challenged the constitutionality of the cannabis extraction
                    rules under the Marihuana for Medical Purposes Regulations.
                    At the Ontario Court of Appeal, his court challenge to the
                    cannabis grower privacy laws ended the ongoing privacy
                    breaches in which the police would access, without warrant,
                    hydro consumption records of cannabis growers. His most
                    recent ruling is R v. Howell 2020 ABQB 385 at the Alberta
                    Queens Bench is the first case to successfully strike down
                    the Access to Cannabis for Medical Purposes Regulations as
                    unconstitutional due to serious infringements of the rights
                    of medical cannabis patients. You can read the ruling here:
                    <OutboundLink
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.canlii.org/en/ab/abqb/doc/2020/2020abqb385/2020abqb385.html"
                    >
                      https://www.canlii.org/en/ab/abqb/doc/2020/2020abqb385/2020abqb385.html
                    </OutboundLink>{" "}
                  </p>
                  <p>
                    Paul was on the Board of Directors of the National
                    Organization for the Reform of Marijuana Laws in Canada
                    (NORML Canada){" "}
                    <OutboundLink
                      target="_blank"
                      rel="noreferrer"
                      href="http://www.norml.ca"
                    >
                      http://www.norml.ca
                    </OutboundLink>{" "}
                    advocating for cannabis reform from 2011 to 2020. He
                    spearheaded advocacy campaigns across the country including
                    a national ranking of politicians on their cannabis
                    positions. He lobbied politicians at all levels of
                    government. He has spoken at cannabis events, he has
                    moderated cannabis policy discussions and he has been
                    interviewed dozens of times in the media on cannabis policy.
                    Paul has advised doctors, lawyers, politicians, and the
                    police on cannabis laws and cannabis policy.
                  </p>
                  <p>
                    Paul has advised medical professionals across Canada on laws
                    and regulations in the psychedelic industry. Paul has a deep
                    understanding of the developing psychedelic legal landscape.
                    The medicinal use of psychedelics is following a similar
                    path as the early regulation of medicinal cannabis. Paul
                    knows this road well. He has litigated issues related to s.
                    56 of the CDSA. Paul advises widely on the laws and
                    regulations within the psychedelic industry.
                  </p>
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <Col md="4" className="mb-4">
                  <img
                    className="rounded-circle img-fluid img-raised"
                    src={Jack}
                    alt="Jack Lloyd: Cannabis Lawyer"
                  />
                </Col>
                <Col md="8">
                  <h4 className="font-weight-bold mb-0 mt-0">JACK LLOYD</h4>
                  <p className="job-title">BARRISTER & SOLICITOR</p>
                  <p>
                    Jack Lloyd is a Cannabis lawyer practicing across Canada
                    excluding Quebec. He articled with Paul Lewin at Lewin &
                    Sagara LLP, a prominent cannabis law firm in Toronto. After
                    finishing articling he joined Kirk Tousaw at Tousaw Law
                    Corporation (
                    <OutboundLink
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.tousawlaw.ca"
                    >
                      https://www.tousawlaw.ca
                    </OutboundLink>
                    ) where he worked as an Associate until the firm closed in
                    2018 at which time he began Lloyd Law Professional
                    Corporation where he is now the principal lawyer.
                  </p>
                  <p>
                    Tousaw Law Corporation was Canada’s foremost cannabis
                    litigation boutique and was the firm responsible for R v
                    Smith 2015 SCC 34 (
                    <OutboundLink
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.canlii.org/en/ca/scc/doc/2015/2015scc34/2015scc34.html"
                    >
                      https://www.canlii.org/en/ca/scc/doc/2015/2015scc34/2015scc34.html
                    </OutboundLink>
                    ) which was the per curiam decision from the Supreme Court
                    of Canada which legalized cannabis edibles (and other
                    extracts and derivative medicines) for medical cannabis
                    patients in Canada. His practice consists primarily of
                    criminal and constitutional litigation relating to medical
                    cannabis regulation as well as consultancy work relating to
                    the cannabis industry and the emergent issues surrounding
                    psilocybin for medical purposes.
                  </p>
                  <p>
                    He has appeared at all levels of court in Ontario and
                    British Columbia, as well as the Supreme Court of Canada,
                    always in relation to cannabis. Prior to becoming a lawyer,
                    he was the editor at Green Candy Press, the world’s largest
                    publisher of books about the cannabis plant and psilocybin
                    mushrooms. He continues to edit select titles for Green
                    Candy Press, including an update to Greg Green’s seminal
                    book on cannabis genetics, The Cannabis Breeder’s Bible, as
                    well as several cannabis and psilocybin cookbooks, including
                    The Ganja Kitchen Revolution{" "}
                    <OutboundLink
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.amazon.ca/Ganja-Kitchen-Revolution-Cannabis-Cuisine/dp/1937866009/ref=sr_1_1?dchild=1&keywords=ganja+kitchen+revolution&qid=1607780249&sr=8-1"
                    >
                      https://www.amazon.ca/Ganja-Kitchen-Revolution-Cannabis-Cuisine/dp/1937866009/ref=sr_1_1?dchild=1&keywords=ganja+kitchen+revolution&qid=1607780249&sr=8-1
                    </OutboundLink>
                    and Marijuana Chef Cookbook
                    <OutboundLink
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.amazon.ca/Marijuana-Chef-Cookbook-S-Oner/dp/193786619X/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=&sr="
                    >
                      https://www.amazon.ca/Marijuana-Chef-Cookbook-S-Oner/dp/193786619X/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=&sr=
                    </OutboundLink>
                    as well as the Psilocybin Chef Cookbook{" "}
                    <OutboundLink
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.amazon.ca/Psilocybin-Chef-Cookbook-Dr-Mandrake/dp/1937866416/ref=sr_1_2?dchild=1&keywords=psilocybin+chef+cookbook&qid=1607780343&s=books&sr=1-2"
                    >
                      https://www.amazon.ca/Psilocybin-Chef-Cookbook-Dr-Mandrake/dp/1937866416/ref=sr_1_2?dchild=1&keywords=psilocybin+chef+cookbook&qid=1607780343&s=books&sr=1-2
                    </OutboundLink>
                    . In 2016 he edited The Psilocybin Mushroom Bible: The
                    Definitive Guide to Growing and Using Magic Mushrooms:{" "}
                    <OutboundLink
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.amazon.ca/Psilocybin-Mushroom-Bible-Definitive-Mushrooms/dp/1937866289/ref=sr_1_2?dchild=1&keywords=psilocybin+mushroom+bible&qid=1607780161&sr=8-2"
                    >
                      https://www.amazon.ca/Psilocybin-Mushroom-Bible-Definitive-Mushrooms/dp/1937866289/ref=sr_1_2?dchild=1&keywords=psilocybin+mushroom+bible&qid=1607780161&sr=8-2
                    </OutboundLink>
                    which was the first book on psilocybin mushroom cultivation
                    released in over a decade. He is the President of the
                    National Organization for the Reform of Marijuana Laws in
                    Canada (NORML Canada) where he advocates for sensible
                    regulations concerning cannabis in Canada (www.norml.ca).
                  </p>
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <Col md="4" className="mb-4">
                  <img
                    className="rounded-circle img-fluid img-raised"
                    src={Michael}
                    alt="Michael Starogiannis: Business Consultant"
                  />
                </Col>
                <Col md="8">
                  <h4 className="font-weight-bold mb-2 mt-0">
                    MICHAEL STAROGIANNIS
                  </h4>
                  <p>
                    Michael has practiced as a business consultant for the last
                    6 years assisting small companies and start-ups with
                    business plans, business strategy, corporate development,
                    financial modelling, and corporate governance. Prior to
                    business consulting, Michael worked for almost 10 years as
                    an investment analyst in the resource sector, working for a
                    number of Toronto based investment banks where his work
                    involved the methodical screening and valuation of projects,
                    management teams, and companies. Michael also has experience
                    in residential and commercial construction having worked as
                    4 years as a general contractor/builder. Prior to his time
                    on Bay Street Mike practiced as a professional engineer in
                    the mining and heavy civil infrastructure sectors.
                  </p>
                  <p>
                    Michael brings over 24 years of experience in various
                    capacities of analysis, design, governance, and project
                    management to bear on behalf of the firm’s clients to refine
                    business plans, perfect license applications, and advance
                    entrepreneurial projects from concept to reality. Much of
                    Michael’s professional career has been spent in the field,
                    interacting with contractors, subcontractors, trades
                    peoples, design professionals, and technical specialists.
                    This experience is applied daily on behalf of the firm’s
                    clients.
                  </p>
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <Col md="4" className="mb-4">
                  <img
                    className="rounded-circle img-fluid img-raised"
                    src={Daniel}
                    alt="Daniel Perlin: Corporate Commerical Lawyer"
                  />
                </Col>
                <Col md="8">
                  <h4 className="font-weight-bold mb-2 mt-0">DANIEL PERLIN</h4>
                  <p>
                    Daniel Perlin is a corporate commercial lawyer with over ten
                    years of experience. He is a member of the Law Society of
                    Ontario and the New York State Bar. He holds a Masters in
                    Business Administration in addition to his law degree.
                  </p>
                  <p>
                    He is the principal lawyer at Guardian Legal Consultants in
                    Toronto, a boutique business and litigation law firm that
                    offers individuals and businesses creative and effective
                    legal solutions in a wide variety of areas{" "}
                    <OutboundLink
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.guardianlc.com"
                    >
                      https://www.guardianlc.com
                    </OutboundLink>
                  </p>
                  <p>
                    Daniel worked previously for a large corporate law firm
                    where he dealt with major corporate transactions. He left
                    law and pursued a career in finance for a large American
                    bank.
                  </p>
                  <p>
                    Daniel brings his experience and knowledge to bear for
                    Cannabis and Psychedelics Law Group LLP to assist with all
                    facets of your business. He has deep knowledge of the issues
                    and concerns facing start-ups. His goal is to work with
                    start-ups every step of the way to ensure that all the legal
                    provisions are in and safeguards are in place, so that the
                    founders can focus on one thing – growing their cannabis or
                    psychedelics company. Whether an entrepreneur needs to get
                    incorporated and organized, have the organizational
                    agreements prepared, or help facilitate funding to make sure
                    their rights and interests are protected, Daniel is here to
                    help.
                  </p>
                </Col>
              </Row>
              <hr/>
              <Row className="mt-4">
                <Col md="4" className="mb-4">
                  <img
                    className="rounded-circle img-fluid img-raised"
                    src={Jasdeep}
                    alt="Jasdeep Bal: Lead Litigation Lawyer"
                  />
                </Col>
                <Col md="8">
                  <h4 className="font-weight-bold mb-2 mt-0">JASDEEP BAL</h4>
                  <p>
                  Jasdeep is the lead litigation lawyer at Cannabis and Psychedelics 
                  Law Group. Jasdeep has extensive experience in insurance coverage, 
                  product liability, municipal law, regulatory law, shareholder disputes, 
                  commercial litigation and property disputes. He provides holistic 
                  advice with a view of finding a practical solution for his clients, 
                  and often weaves together bespoke legal services that blends his experience 
                  across various fields. He uses his experience and knowledge of the law 
                  to achieve a cost-effective solution, often without resorting to litigation, 
                  while being ever ready to fight for his clients in the courtroom.  
                  </p>
                  <p>
                  Jasdeep is also the principal litigation lawyer at Guardian Legal 
                  Consultants, a boutique law firm that offers creative, effective, and 
                  practical legal solutions across litigation, corporate, employment, 
                  and real estate law. 
                  </p>
                </Col>
              </Row>
            </Card>
          </Container>
        </div>
      </div>
    </Fragment>
  );
};

export default PeoplePage;
